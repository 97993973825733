import React, { useState, useEffect } from 'react';
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  TextField,
  Button,
  Grid,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  CircularProgress,
  Snackbar,
} from '@mui/material';
import {
  fetchProducts,
  searchProducts,
  addProduct,
  updateProduct,
  deleteProduct
} from '../../../api/Products';
import SearchIcon from '@mui/icons-material/Search';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import Edit from '@mui/icons-material/Edit';
import Delete from '@mui/icons-material/Delete';
import './Products.scss';

export const Products = () => {
  const [products, setProducts] = useState([]);
  const [name, setName] = useState('');
  const [duration, setDuration] = useState('');
  const [filterName, setFilterName] = useState('');
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [openSnackbar, setOpenSnackbar] = useState(false);

  useEffect(() => {
    handleFetchProducts();
  }, []);

  const handleFetchProducts = async () => {
    setLoading(true);
    try {
      const data = await fetchProducts();
      setProducts(data);
    } catch (error) {
      setErrorMessage('Nenhum produto encontrado.');
      setOpenSnackbar(true);
    } finally {
      setLoading(false);
    }
  };

  const handleSearch = async () => {
    setLoading(true);
    try {
      const data = await searchProducts(filterName);
      setProducts(data);
    } catch (error) {
      setErrorMessage('Nenhum produto encontrado.');
      setOpenSnackbar(true);
    } finally {
      setLoading(false);
    }
  };

  const handleAddProduct = () => {
    setIsEditing(false);
    setName('');
    setDuration('');
    setDialogOpen(true);
  };

  const handleEditProduct = (product) => {
    setIsEditing(true);
    setSelectedProduct(product);
    setName(product.name);
    setDuration(product.duration);
    setDialogOpen(true);
  };

  const handleDeleteProduct = async (productId) => {
    setLoading(true);
    try {
      await deleteProduct(productId);
      handleFetchProducts();
    } catch (error) {
      setErrorMessage('Erro ao deletar produto.');
      setOpenSnackbar(true);
    } finally {
      setLoading(false);
    }
  };

  const handleSaveProduct = async () => {
    setLoading(true);
    try {
      if (isEditing) {
        await updateProduct(selectedProduct.id, name, duration);
      } else {
        await addProduct(name, duration);
      }
      handleFetchProducts();
      setDialogOpen(false);
    } catch (error) {
      setErrorMessage('Erro ao salvar produto.');
      setOpenSnackbar(true);
    } finally {
      setLoading(false);
    }
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  return (
    <div className='products'>
      <Paper className='paper_top'>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12}>
            <TextField
              variant="filled"
              label="Nome do Produto"
              value={filterName}
              onChange={(e) => setFilterName(e.target.value)}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <Box display="flex" gap={2}>
              <Button variant="contained" onClick={handleSearch}><SearchIcon/>Filtrar</Button>
              <Button variant="contained" onClick={handleAddProduct}><AddCircleIcon/>Registrar Novo Produto</Button>
            </Box>
          </Grid>
        </Grid>
      </Paper>
      <Paper className='paper_table'>
        {loading ? (
          <Box display="flex" justifyContent="center" alignItems="center" height="100%">
            <CircularProgress className='circular_progress'/>
          </Box>
        ) : (
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Nome</TableCell>
                <TableCell>Duração (Meses)</TableCell>
                <TableCell>Ações</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {products.map((product) => (
                <TableRow key={product.id}>
                  <TableCell>{product.name}</TableCell>
                  <TableCell>{product.duration} meses</TableCell>
                  <TableCell>
                    <Box display="flex" gap={2}>
                      <Button onClick={() => handleEditProduct(product)}><Edit/>Editar</Button>
                      <Button onClick={() => handleDeleteProduct(product.id)}><Delete/>Excluir</Button>
                    </Box>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}
      </Paper>
      <Dialog open={dialogOpen} onClose={handleDialogClose}>
        <h1 className='text-xl text-text pt-6 pl-6'>{isEditing ? 'Editar Produto' : 'Registrar Novo Produto'}</h1>
        <DialogContent>
          <TextField
            label="Nome"
            value={name}
            onChange={(e) => setName(e.target.value)}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Duração (Meses)"
            type="number"
            value={duration}
            onChange={(e) => setDuration(e.target.value)}
            fullWidth
            margin="normal"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose}>Cancelar</Button>
          <Button onClick={handleSaveProduct} color="primary">{isEditing ? 'Salvar' : 'Registrar'}</Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        message={errorMessage}
        className='snackbar-custom blank'
      />
    </div>
  );
};

export default Products;
