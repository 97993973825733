import React from 'react';
import PasswordRecovery from '../../../components/PasswordRecovery/PasswordRecovery';
import './PasswordRecoveryPage.scss';

const PasswordRecoveryPage = () => {
  const handleLogin = (username, password) => {
    console.log("Login attempt with:", username, password);
    // Lógica de envio
  };

  return (
    <div className="PasswordRecoveryPage">
      <PasswordRecovery onLogin={handleLogin} />
    </div>
  );
};

export default PasswordRecoveryPage;
