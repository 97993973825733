import React, { useState, useEffect } from 'react';
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  TextField,
  MenuItem,
  Grid,
  Snackbar,
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  CircularProgress,
} from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import DatePicker from 'react-datepicker';
import ImportExportIcon from '@mui/icons-material/ImportExport';
import 'react-datepicker/dist/react-datepicker.css';
import { 
    fetchFinances, 
    fetchClientData, 
    fetchClientPayments, 
    importAsaasData, 
    importPagarmeData 
} from '../../../api/Finances';
import ClientDrawer from '../../../components/Dashboard/ClientDrawer/ClientDrawer';
import { format } from 'date-fns';
import './Finance.scss';

export const Finance = () => {
  const [finances, setFinances] = useState([]);
  const [status, setStatus] = useState('');
  const [paymentMethod, setPaymentMethod] = useState('');
  const [initialDate, setInitialDate] = useState(null);
  const [finalDate, setFinalDate] = useState(null);
  const [totalValue, setTotalValue] = useState(0);
  const [totalNetValue, setTotalNetValue] = useState(0);
  const [selectedClient, setSelectedClient] = useState(null);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [uploadDialogOpen, setUploadDialogOpen] = useState(false);
  const [uploadingAsaas, setUploadingAsaas] = useState(false);
  const [uploadingPagarme, setUploadingPagarme] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchFilteredFinances();
    // eslint-disable-next-line
  }, [status, paymentMethod, initialDate, finalDate]);

  const fetchFilteredFinances = async () => {
    setLoading(true);
    try {
      const data = await fetchFinances({
        status,
        payment_method: paymentMethod,
        initial_date: initialDate ? initialDate.toISOString().split('T')[0] : '',
        final_date: finalDate ? finalDate.toISOString().split('T')[0] : '',
      });
      setFinances(data);

      // Calcular o total do value e net_value
      let totalValue = 0;
      let totalNetValue = 0;
      data.forEach((finance) => {
        totalValue += parseFloat(finance.value);
        totalNetValue += parseFloat(finance.net_value);
      });
      setTotalValue(totalValue);
      setTotalNetValue(totalNetValue);
    } catch (error) {
      setErrorMessage('Erro ao buscar finanças.');
      setOpenSnackbar(true);
    } finally {
      setLoading(false);
    }
  };

  // Função para formatar valores para reais
  const formatToCurrency = (value) => {
    return new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(value);
  };

  // Função para formatar a data
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return format(date, 'dd/MM/yyyy');
  };

  // Função para lidar com o clique em uma linha da tabela
  const handleTableRowClick = async (clientId) => {
    try {
      // Buscar dados do cliente
      const clientData = await fetchClientData(clientId);

      // Buscar pagamentos do cliente
      const clientPayments = await fetchClientPayments(clientId);

      // Calcular o total pago
      const totalPaid = clientPayments.reduce((total, payment) => {
        if (payment.status === 'paid' || payment.status === 'RECEIVED') {
          return total + parseFloat(payment.value);
        }
        return total;
      }, 0);

      // Atualizar estado com os dados do cliente e os pagamentos
      setSelectedClient({
        clientData,
        clientPayments,
        totalPaid
      });

      setDrawerOpen(true);
    } catch (error) {
      setErrorMessage('Erro ao buscar dados do cliente.');
      setOpenSnackbar(true);
    }
  };

  // Função para fechar o drawer
  const handleDrawerClose = () => {
    setDrawerOpen(false);
    setSelectedClient(null);
  };

  // Função para definir a cor do status
  const getStatusClass = (status) => {
    switch (status) {
      case 'RECEIVED':
        return 'status_received';
      case 'PENDING':
        return 'status_pending';
      case 'CANCELLED':
        return 'status_cancelled';
      case 'REFUNDED':
        return 'status_refunded';
      case 'OVERDUE':
        return 'status_overdue';
      case 'paid':
        return 'status_received';
      case 'pending':
        return 'status_pending';
      case 'waiting_payment':
        return 'status_pending';
      case 'canceled':
        return 'status_cancelled';
      case 'failed':
        return 'status_cancelled';
      case 'refused':
        return 'status_cancelled';
      case 'refunded':
        return 'status_refunded';
      default:
        return '';
    }
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  // Função para exibir "Todos" ou o valor selecionado
  const renderMenuItemValue = (value) => {
    return value === '' ? 'Todos' : value;
  };

  const handleImportAsaas = async () => {
    setUploadingAsaas(true);
    try {
      await importAsaasData();
      fetchFilteredFinances();
    } catch (error) {
      if (error.response && error.response.status === 404) {
        setErrorMessage('Nenhum cliente encontrado em sua base.');
      } else {
        setErrorMessage('Erro ao importar dados do Asaas.');
      }
      setOpenSnackbar(true);
    } finally {
      setUploadingAsaas(false);
    }
  };

  const handleImportPagarme = async () => {
    setUploadingPagarme(true);
    try {
      await importPagarmeData();
      fetchFilteredFinances();
    } catch (error) {
      if (error.response && error.response.status === 404) {
        setErrorMessage('Nenhum cliente encontrado em sua base.');
      } else {
        setErrorMessage('Erro ao importar dados do Pagarme.');
      }
      setOpenSnackbar(true);
    } finally {
      setUploadingPagarme(false);
    }
  };

  return (
    <div className='finance'>
      <Paper className='paper_top'>
        <Grid container alignItems="center" className='flex items-center justify-between mb-6'>
          <Grid item xs={3}>
            <TextField
              select
              variant="filled"
              label="Status"
              value={status}
              onChange={(e) => setStatus(e.target.value)}
              fullWidth
              IconComponent={(props) => (
                <ArrowDropDownIcon {...props} className="custom_icon" />
              )}
            >
              <MenuItem value="">{renderMenuItemValue('Todos')}</MenuItem>
              <MenuItem value="RECEIVED">{renderMenuItemValue('Pagos')}</MenuItem>
              <MenuItem value="PENDING">{renderMenuItemValue('Pendentes')}</MenuItem>
              <MenuItem value="CANCELLED">{renderMenuItemValue('Cancelados')}</MenuItem>
              <MenuItem value="REFUSED">{renderMenuItemValue('Recusados')}</MenuItem>
              <MenuItem value="REFUNDED">{renderMenuItemValue('Reembolsado')}</MenuItem>
              <MenuItem value="OVERDUE">{renderMenuItemValue('Atrasados')}</MenuItem>
            </TextField>
          </Grid>
          <Grid item xs={3}>
            <TextField
              select
              variant="filled"
              label="Método de Pagamento"
              value={paymentMethod}
              onChange={(e) => setPaymentMethod(e.target.value)}
              fullWidth
              IconComponent={(props) => (
                <ArrowDropDownIcon {...props} className="custom_icon" />
              )}
            >
              <MenuItem value="">Todos</MenuItem>
              <MenuItem value="BOLETO">Boleto</MenuItem>
              <MenuItem value="CREDIT_CARD">Cartão de Crédito</MenuItem>
              <MenuItem value="PIX">PIX</MenuItem>
            </TextField>
          </Grid>
          <Grid item className='cursor-pointer'>
            <DatePicker
              selected={initialDate}
              onChange={(date) => setInitialDate(date)}
              selectsStart
              startDate={initialDate}
              endDate={finalDate}
              dateFormat="dd/MM/yyyy"
              locale="pt"
              className="p-2 border border-gray-700 rounded bg-component cursor-pointer"
              placeholderText="Data Inicial"
              customInput={
                <TextField
                  variant="filled"
                  label="Data Inicial"
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                />
              }
            />
          </Grid>
          <Grid item>
            <DatePicker
              selected={finalDate}
              onChange={(date) => setFinalDate(date)}
              selectsEnd
              startDate={initialDate}
              endDate={finalDate}
              minDate={initialDate}
              dateFormat="dd/MM/yyyy"
              locale="pt"
              className="p-2 border border-gray-700 rounded bg-component cursor-pointer"
              placeholderText="Data Final"
              customInput={
                <TextField
                  variant="filled"
                  label="Data Final"
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                />
              }
            />
          </Grid>
          <Grid item xs={2} className='flex gap-4'>
            <Button variant="contained" onClick={() => setUploadDialogOpen(true)}><ImportExportIcon/>Importar Pagamentos</Button>
          </Grid>
        </Grid>
      </Paper>
      <Paper className='paper_table'>
        {loading ? (
          <div className="flex justify-center py-4">
            <CircularProgress className='circular_progress'/>
          </div>
        ) : (
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>ID Pagamento</TableCell>
                <TableCell>Valor</TableCell>
                <TableCell>Descrição</TableCell>
                <TableCell>Método</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Vencimento</TableCell>
                <TableCell>Pagamento</TableCell>
                <TableCell>Parcela</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {finances.map((finance) => (
                <TableRow key={finance.id} onClick={() => handleTableRowClick(finance.id_client)}>
                  <TableCell>{finance.id_payment}</TableCell>
                  <TableCell>{formatToCurrency(finance.value)}</TableCell>
                  <TableCell>{finance.description}</TableCell>
                  <TableCell>{finance.payment_method}</TableCell>
                  <TableCell className={getStatusClass(finance.status)}>{finance.status}</TableCell>
                  <TableCell>{formatDate(finance.due_date)}</TableCell>
                  <TableCell>{formatDate(finance.payment_date)}</TableCell>
                  <TableCell>{finance.installment_number}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}
      </Paper>
      <Paper className='total_bar'>
        <div className='total_value'>
          <h3 className='text-sm'>Entrada Total</h3>
          <p>{formatToCurrency(totalValue)}</p>
        </div>
        <div className='total_value_liquid'>
          <h3 className='text-sm'>Total Líquido</h3>
          <p>{formatToCurrency(totalNetValue)}</p>
        </div>
      </Paper>
      <ClientDrawer
        open={drawerOpen}
        onClose={handleDrawerClose}
        client={selectedClient}
        formatToCurrency={formatToCurrency}
        getStatusClass={getStatusClass}
      />
      <Dialog open={uploadDialogOpen} onClose={() => setUploadDialogOpen(false)}>
        <div className='w-[490px] text-white p-4'>
          <DialogContent>
            <h1 className='text-xl font-500 mb-4'>Importar Pagamentos</h1>
            <p className='mb-4'>Escolha a origem dos dados para importar:</p>
            <div className='flex gap-4'>
              <Button
                variant="contained"
                onClick={handleImportAsaas}
                disabled={uploadingAsaas}
                startIcon={uploadingAsaas && <CircularProgress size={20} className='text-textContrast' />}
              >
                Importar do Asaas
              </Button>
              <Button
                variant="contained"
                onClick={handleImportPagarme}
                disabled={uploadingPagarme}
                startIcon={uploadingPagarme && <CircularProgress size={20} className='text-textContrast' />}
                className='btn_import'
              >
                Importar da Pagarme
              </Button>
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setUploadDialogOpen(false)}>Fechar</Button>
          </DialogActions>
        </div>
      </Dialog>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        message={errorMessage}
        className='snackbar-custom blank'
      />
    </div>
  );
};

export default Finance;
