import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './PasswordRecovery.scss';
import {
  TextField,
  Button,
  Container,
  Box
} from '@mui/material';
import { ArrowBack } from '@mui/icons-material';
import Logo from '../../assets/images/logo.avif';

const PasswordRecovery = () => {
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');
  const [sentSuccess, setSentSuccess] = useState(null);

  const validateForm = () => {
    let isValid = true;
    if (!email) {
      setEmailError('Não há nenhum usuário com este e-mail');
      isValid = false;
    } else if (!/^\S+@\S+\.\S+$/.test(email)) {
      setEmailError('E-mail inválido.');
      isValid = false;
    } else {
      setEmailError('');
    }

    return isValid;
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (validateForm()) {
      console.log(email);
      // Lógica de envio aqui
      setSentSuccess(true);
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <div className="recovery_form">

        {sentSuccess !== false ? (
          <>
            <div className="logo">
              <img src={Logo} alt="Logo" />
            </div>

            <div className="information">
              <p>
              Confira os seus dados e clique em "Resetar senha". 
              Você receberá um e-mail com as instruções para a 
              troca de senha na sua caixa de entrada.
              </p>
            </div>

            <Box className="box_recovery" component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
              <TextField
                variant="filled"
                margin="normal"
                required
                fullWidth
                id="email"
                label="E-mail"
                name="email"
                autoComplete="email"
                autoFocus
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                error={!!emailError}
                helperText={emailError}
                className="custom_textfield"
              />
              <Button className="button_login">
                Enviar e-mail de redefinição
              </Button>
              <Link to="/login" className="btn_return">
                <ArrowBack />
                Voltar
              </Link>
            </Box>
          </>

        ) : (
          <>
            <div className="icon_first_access">
              X
            </div>

            <div className="information">
              Aguardando sua redefinição
            </div>

            <div className="verify_email">
              Acesse seu e-mail e clique no link de redefinição de senha que enviamos
            </div>
          </>
        )}

      </div>


    </Container>
  );
};

export default PasswordRecovery;
