import client from './client';
import { createBrowserHistory } from 'history';
const browserHistory = createBrowserHistory();

const refreshAuthToken = async (refreshToken) => {
    if (!refreshToken) {
        throw new Error('No refresh token available');
    }
    try {
        const response = await client.post('refresh-token', { refreshToken }); // Passando o refreshToken no corpo da requisição
        localStorage.setItem('accessToken', response.data.accessToken); // Supondo que o novo token seja retornado como 'accessToken'
        return response.data.accessToken;
    } catch (error) {
        console.error('Failed to refresh token:', error);
        throw error;
    }
};

let isRefreshing = false;
let refreshSubscribers = [];

const setAxiosInterceptors = (queryClient, axiosInstance, setAxiosToken) => {
    axiosInstance.interceptors.response.use(
        response => response,
        async error => {
            const { config, response } = error;
            const originalRequest = config;

            if (response?.status === 401 && !originalRequest._retry) {
                if (isRefreshing) {
                    return new Promise(resolve => {
                        refreshSubscribers.push(accessToken => {
                            originalRequest.headers['Authorization'] = `Bearer ${accessToken}`;
                            resolve(axiosInstance(originalRequest));
                        });
                    });
                }

                originalRequest._retry = true;
                isRefreshing = true;

                try {
                    const currentToken = localStorage.getItem('refreshToken'); 
                    const accessToken = await refreshAuthToken(currentToken);

                    localStorage.setItem('accessToken', accessToken); 
                    queryClient.setQueryData('accessToken', accessToken);
                    setAxiosToken(accessToken);

                    isRefreshing = false;
                    refreshSubscribers.forEach(subscriber => subscriber(accessToken));
                    refreshSubscribers = [];

                    originalRequest.headers['Authorization'] = `Bearer ${accessToken}`;
                    return axiosInstance(originalRequest);
                } catch (refreshError) {
                    isRefreshing = false;
                    queryClient.invalidateQueries();
                    //console.log('Sua sessão expirou, faça o login novamente.');
                    browserHistory.push('/login');
                    return Promise.reject(refreshError);
                }
            }

            return Promise.reject(error);
        }
    );
};

export default setAxiosInterceptors;
