import React, { useState, useEffect } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import requireAuth from '../api/requireAuth';
import { CircularProgress } from '@mui/material';

const PrivateRoute = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const checkAuthentication = async () => {
      try {
        const refresh_token = localStorage.getItem('refreshToken');
        if (!refresh_token) {
          throw new Error('No token found');
        }

        await requireAuth.requireAuth();
        setIsAuthenticated(true);

      } catch (error) {
        console.error('Authentication failed:', error);
        navigate('/login');
      }
    };

    checkAuthentication();
  }, [navigate]);

  if (isAuthenticated === null) {
    return (
      <div className="flex items-center justify-center h-screen">
          <CircularProgress size={32} style={{ color: 'white' }} />
      </div>
    );
  }

  return isAuthenticated ? children : <Navigate to="/login" />;
};

export default PrivateRoute;
