import { axiosInstance } from './axiosConfig';

export const fetchDashboardData = async () => {
  try {
    const response = await axiosInstance.get('dashboard');
    return response.data;
  } catch (error) {
    throw error;
  }
};
