import { axiosInstance } from './axiosConfig';

const updatePassword = async (currentPassword, newPassword) => {
    try {
        const response = await axiosInstance.post('update-password', {
            currentPassword: currentPassword,
            newPassword: newPassword,
        });
        return response.data;

    } catch (error) {
        throw error; 
    }
};

export default updatePassword;
