import { axiosInstance } from './axiosConfig';

export const fetchFinances = async (filters) => {
  try {
    const response = await axiosInstance.post('list-payments', filters);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const fetchClientData = async (clientId) => {
  try {
    const response = await axiosInstance.post('clients/search', { id: clientId });
    return response.data[0]; // Ajustando para acessar o primeiro item da lista retornada
  } catch (error) {
    throw error;
  }
};

export const fetchClientPayments = async (clientId) => {
  try {
    const response = await axiosInstance.get(`payments-by-client/${clientId}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

// Responsável pela importação dos dados de Asaas
export const importAsaasData = async () => {
  try {
    const response = await axiosInstance.post('migration-asaas');
    return response.data;
  } catch (error) {
    if (error.response && error.response.status === 404) {
      throw new Error('Nenhum cliente encontrado em sua base.');
    } else if (error.response && error.response.data) {
      throw new Error(error.response.data.message);
    } else {
      throw new Error('Erro ao importar dados do Asaas.');
    }
  }
};

// Responsável pela importação dos dados de Pagarme
export const importPagarmeData = async () => {
  try {
    const response = await axiosInstance.post('migration-pagarme');
    return response.data;
  } catch (error) {
    if (error.response && error.response.status === 404) {
      throw new Error('Nenhum cliente encontrado em sua base.');
    } else if (error.response && error.response.data) {
      throw new Error(error.response.data.message);
    } else {
      throw new Error('Erro ao importar dados do Pagarme.');
    }
  }
};
