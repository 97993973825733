import React, { useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
import 'chart.js/auto';
import { format, parseISO, subDays } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './Panel.scss';
import PeopleIcon from '@mui/icons-material/People';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import { fetchDashboardData } from '../../../api/Dashboard';

// Registrar a localidade
import { pt } from 'date-fns/locale';
registerLocale('pt', pt);

const Panel = () => {
    const [data, setData] = useState({
        clientsCount: 0,
        paymentsCount: 0,
        totalNetValue: 'R$ 0,00',
        todaysTotalNetValue: 'R$ 0,00',
        salesData: []
    });
    const [filter, setFilter] = useState('last30days');
    const [startDate, setStartDate] = useState(subDays(new Date(), 30));
    const [endDate, setEndDate] = useState(new Date());

    useEffect(() => {
        const fetchData = async () => {
            try {
                const dashboardData = await fetchDashboardData();
                setData(dashboardData);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, []);

    const formatDate = (dateString) => {
        const date = parseISO(dateString);
        return format(date, 'dd/MM/yyyy', { locale: ptBR });
    };

    const filterData = (salesData) => {
        const now = new Date();
        let filteredData = salesData;

        if (filter === 'last30days') {
            const last30Days = subDays(now, 30);
            filteredData = salesData.filter(item => parseISO(item.date) >= last30Days);
        } else if (filter === 'last7days') {
            const last7Days = subDays(now, 7);
            filteredData = salesData.filter(item => parseISO(item.date) >= last7Days);
        } else if (filter === 'custom') {
            filteredData = salesData.filter(item => parseISO(item.date) >= startDate && parseISO(item.date) <= endDate);
        }

        return filteredData.sort((a, b) => new Date(a.date) - new Date(b.date));
    };

    const sortedSalesData = filterData(data.salesData);

    const salesData = {
        labels: sortedSalesData.map(item => formatDate(item.date)),
        datasets: [
            {
                label: 'Vendas',
                data: sortedSalesData.map(item => item.value),
                fill: false,
                backgroundColor: 'rgb(215, 168, 107)',
                borderColor: 'rgb(215, 168, 107, 0.4)',
            },
        ],
    };

    return (
        <div className="panel grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
            <div className="h-32 card bg-component shadow-md p-4 rounded-lg">
                <h2 className="text-xl font-semibold">
                    Mentorados
                </h2>
                <p className="mt-2 text-2xl flex gap-2 items-center">
                    <PeopleIcon className='icons_dashboard' />
                    {data.clientsCount}
                </p>
            </div>
            <div className="h-32 card bg-component text-text shadow-md p-4 rounded-lg">
                <h2 className="text-xl font-semibold">
                    Pagamentos Totais
                </h2>
                <p className="mt-2 text-2xl flex gap-2 items-center">
                    <AttachMoneyIcon className='icons_dashboard' />
                    {data.paymentsCount}
                </p>
            </div>
            <div className="h-32 card bg-component text-text shadow-md p-4 rounded-lg">
                <h2 className="text-xl font-semibold">
                    Valor total
                </h2>
                <p className="mt-2 text-2xl flex gap-2 items-center">
                    <MonetizationOnIcon className='icons_dashboard' />
                    {data.totalNetValue}
                </p>
            </div>
            <div className="h-32 card bg-component text-text shadow-md p-4 rounded-lg">
                <h2 className="text-xl font-semibold">
                    Recebido hoje
                </h2>
                <p className="mt-2 text-2xl flex gap-2 items-center">
                    <AttachMoneyIcon className='icons_dashboard' />
                    {data.todaysTotalNetValue}
                </p>
            </div>
            <div className="col-span-1 md:col-span-2 lg:col-span-4">
                <div className="card bg-component shadow-md p-4 rounded-lg">
                    <div className='flex gap-2 justify-between'>
                        <h2 className="text-xl font-semibold">
                            Escala das Vendas
                        </h2>
                        <div className='flex gap-4 items-center'>
                            <div className="mb-4">
                                <select
                                    value={filter}
                                    onChange={(e) => setFilter(e.target.value)}
                                    className="p-2 border border-gray-700 rounded bg-component cursor-pointer"
                                >
                                    <option value="last30days">Últimos 30 dias</option>
                                    <option value="last7days">Últimos 7 dias</option>
                                    <option value="custom">Personalizado</option>
                                </select>
                            </div>
                            {filter === 'custom' && (
                                <div className="mb-4">
                                    <DatePicker
                                        selected={startDate}
                                        onChange={(date) => setStartDate(date)}
                                        selectsStart
                                        startDate={startDate}
                                        endDate={endDate}
                                        dateFormat="dd/MM/yyyy"
                                        locale="pt"
                                        className="p-2 border border-gray-700 rounded bg-component cursor-pointer"
                                    />
                                    <DatePicker
                                        selected={endDate}
                                        onChange={(date) => setEndDate(date)}
                                        selectsEnd
                                        startDate={startDate}
                                        endDate={endDate}
                                        minDate={startDate}
                                        dateFormat="dd/MM/yyyy"
                                        locale="pt"
                                        className="p-2 border border-gray-700 rounded ml-2 bg-component cursor-pointer"
                                    />
                                </div>
                            )}
                        </div>
                    </div>
                    <Line data={salesData} />
                </div>
            </div>
        </div>
    );
};

export default Panel;
