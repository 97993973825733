import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useUser } from '../../../context/UserContext';

const LogoutPage = () => {
  const { clearUserContext } = useUser();
  const navigate = useNavigate();

  useEffect(() => {
    // Limpa o contexto do usuário
    clearUserContext();
     // Limpa o localStorage
     localStorage.removeItem('accessToken');
     localStorage.removeItem('refreshToken');
     // Redireciona para a página de login
     navigate('/login');
  }, [clearUserContext, navigate]);

  return (
    <div className="flex items-center justify-center h-screen">
      <p>Logging out...</p>
    </div>
  );
};

export default LogoutPage;
