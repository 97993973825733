import { axiosInstance } from './axiosConfig';

export const fetchProducts = async () => {
  try {
    const response = await axiosInstance.get('products');
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const searchProducts = async (name) => {
  try {
    const response = await axiosInstance.post('products/search', { name });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const addProduct = async (name, duration) => {
  try {
    const response = await axiosInstance.post('products', { name, duration });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const updateProduct = async (id, name, duration) => {
  try {
    const response = await axiosInstance.put(`products/${id}`, { name, duration });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const deleteProduct = async (id) => {
  try {
    const response = await axiosInstance.delete(`products/${id}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};
