import React from 'react';
import Login from '../../../components/Login/Login';
import './LoginPage.scss';

const LoginPage = () => {
  const handleLogin = (username, password) => {
    console.log("Login attempt with:", username, password);
    // Lógica de autenticação
  };

  return (
    <div className="LoginPage">
      <Login onLogin={handleLogin} />
    </div>
  );
};

export default LoginPage;
