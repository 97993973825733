import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import Logo from '../../../assets/images/logo.avif';
import './Menu.scss';

import HomeOutlinedIcon from '@mui/icons-material/Home';
import AttachMoneyOutlinedIcon from '@mui/icons-material/AttachMoneyOutlined';
import PeopleOutlineOutlinedIcon from '@mui/icons-material/GroupRounded';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import SellRoundedIcon from '@mui/icons-material/SellRounded';
import SettingsIcon from '@mui/icons-material/Settings';
import LogoutIcon from '@mui/icons-material/Logout';
import { useUser } from '../../../context/UserContext';

const LeftMenu = () => {

    const { nome } = useUser();
    const firstName = nome ? nome.split(' ')[0] : 'Usuário';

    const location = useLocation();
    const isActive = (path) => {
        return location.pathname === path;
    };
    return (
        <header className="header bg-component p-6 h-5/6 rounded-2xl fixed">

            <div className="flex flex-col h-full justify-between">
                <div className="row_menu">
                    <Link to="/dashboard" className="logo_dash">
                        <img src={Logo} alt="logo" />
                    </Link>
                    <div className="mt-12 flex flex-col gap-2 font-bold">
                        <Link to="/dashboard" className={`p-2 flex items-center gap-2 rounded-lg ${isActive('/dashboard') ? 'active' : ''}`}>
                            <HomeOutlinedIcon /> Dashboard
                        </Link>
                        <Link to="/financas" className={`p-2 flex items-center gap-2 rounded-lg ${isActive('/financas') ? 'active' : ''}`}>
                            <AttachMoneyOutlinedIcon /> Finanças
                        </Link>
                        <Link to="/mentorados" className={`p-2 flex items-center gap-2 rounded-lg ${isActive('/mentorados') ? 'active' : ''}`}>
                            <PeopleOutlineOutlinedIcon /> Mentorados
                        </Link>
                        <Link to="/produtos" className={`p-2 flex items-center gap-2 rounded-lg ${isActive('/produtos') ? 'active' : ''}`}>
                            <SellRoundedIcon /> Produtos
                        </Link>
                    </div>
                </div>
                <div>
                    <div className="flex-col justify-items-center justify-center items-center text-center">
                        <Link to="/minha-conta">
                            <div class="button_menu">
                                <AccountCircleIcon />
                            </div>
                        </Link>

                        <div className="mt-2">
                            <h3 className='text-lg font-bold'>{firstName}</h3>
                            <span className='text-xs'>Administrador</span>
                        </div>

                        <div className="button_profile">
                            <Link to="/configuracoes"><SettingsIcon /></Link>
                            <Link to="/sair"><LogoutIcon /></Link>
                        </div>
                    </div>
                </div>
            </div>

        </header>
    );
}

export default LeftMenu;
