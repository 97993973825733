// src/context/UserContext.js
import React, { createContext, useState, useContext, useEffect } from 'react';
import { fetchUserData } from '../api/requireUser';
import updateUser from '../api/updateUser';

const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [nome, setNome] = useState('');
  const [email, setEmail] = useState('');
  const [nomeLoading, setNomeLoading] = useState(true);
  const [emailLoading, setEmailLoading] = useState(true);

  const handleUserData = async () => {
    setNomeLoading(true);
    setEmailLoading(true);
    try {
      const userData = await fetchUserData();
      setNome(userData.name);
      setEmail(userData.email);
    } catch (error) {
      console.error('Falha ao carregar dados do usuário:', error);
    } finally {
      setNomeLoading(false);
      setEmailLoading(false);
    }
  };

  useEffect(() => {
    handleUserData();
  }, []);

  const updateUserContext = async (newNome, newEmail) => {
    try {
      await updateUser(newNome, newEmail);
      setNome(newNome);
      setEmail(newEmail);
    } catch (error) {
      console.error('Falha ao atualizar dados do usuário:', error);
    }
  };

  const clearUserContext = () => {
    setNome('');
    setEmail('');
  };

  return (
    <UserContext.Provider value={{ nome, email, nomeLoading, emailLoading, updateUserContext, clearUserContext, handleUserData }}>
      {children}
    </UserContext.Provider>
  );
};

export const useUser = () => {
  return useContext(UserContext);
};
