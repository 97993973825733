import React, { useState, useEffect } from 'react';
import './Header.scss';
import { useTheme } from '../../../context/ThemeContext';
import Brightness7Icon from '@mui/icons-material/Brightness7';
import Brightness2Icon from '@mui/icons-material/Brightness2';
import MenuIcon from '@mui/icons-material/Menu';
import { useUser } from '../../../context/UserContext';

const Header = ({ onMenuToggle }) => {
    const [greeting, setGreeting] = useState('');
    const { theme, toggleTheme } = useTheme();
    const { nome } = useUser();

    useEffect(() => {
        const now = new Date();
        const hour = now.getHours();

        let greetingText = '';

        if (hour >= 5 && hour < 12) {
            greetingText = 'Bom dia';
        } else if (hour >= 12 && hour < 18) {
            greetingText = 'Boa tarde';
        } else {
            greetingText = 'Boa noite';
        }

        const firstName = nome ? nome.split(' ')[0] : 'Usuário';
        setGreeting(`${greetingText}, ${firstName}!`);

    }, [nome]);

    return (
        <header className="md:bg-backgroundNone bg-component w-full fixed items-center p-4 flex h-16 justify-between z-50">
            <div className="flex items-center">
                <div className='block md:hidden'>
                    <MenuIcon className="hidden mr-4 cursor-pointer" onClick={onMenuToggle} />
                </div>
                <h1 className='text-2xl font-bold md:pl-4'>{greeting}</h1>
            </div>
            <div className="toggle-switch">
                <input type="checkbox" id="theme-switch" className="theme-switch-checkbox"
                       checked={theme === 'dark'} onChange={toggleTheme} />
                <label className="theme-switch-label" htmlFor="theme-switch">
                    <span className={`slider ${theme}`}>
                        {theme === 'light' ? <Brightness7Icon /> : <Brightness2Icon />}
                    </span>
                </label>
            </div>
        </header>
    );
}

export default Header;
