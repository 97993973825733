// src/components/MyAccount/MyAccount.jsx
import React, { useState, useEffect } from 'react';
import './MyAccount.scss';
import Visibility from '@mui/icons-material/VisibilityOutlined';
import VisibilityOff from '@mui/icons-material/VisibilityOffOutlined';
import {
  TextField,
  Button,
  Box,
  InputAdornment,
  IconButton,
  CircularProgress,
  Snackbar
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useUser } from '../../../context/UserContext';
import updatePassword from '../../../api/updatePassword';

const MyAccount = () => {
  const { nome, email, nomeLoading, emailLoading, updateUserContext } = useUser();
  const [localNome, setLocalNome] = useState(nome);
  const [localEmail, setLocalEmail] = useState(email);
  const [nomeError, setNomeError] = useState('');
  const [emailError, setEmailError] = useState('');
  const [currentPassword, setCurrentPassword] = useState('');
  const [currentPasswordError, setCurrentPasswordError] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [loadingUser, setLoadingUser] = useState(false);
  const [loadingPassword, setLoadingPassword] = useState(false);
  const [open, setOpen] = useState(false);
  const [errorUpdate, setErrorUpdate] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);

  useEffect(() => {
    setLocalNome(nome);
    setLocalEmail(email);
  }, [nome, email]);

  useEffect(() => {
    setIsFormValid(
      validateNome(localNome) &&
      validateEmail(localEmail) &&
      currentPassword !== '' &&
      validatePasswords(newPassword, confirmPassword) &&
      newPassword.length >= 6
    );
  }, [localNome, localEmail, currentPassword, newPassword, confirmPassword]);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const validateNome = (nome) => {
    const re = /^[a-zA-ZáàâãéèêíïóôõöúçñÁÀÂÃÉÈÊÍÏÓÔÕÖÚÇÑ' -]+$/;
    return re.test(String(nome).trim());
  };

  const validateEmail = (email) => {
    const re = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
    return re.test(String(email).trim().toLowerCase());
  };

  const validatePasswords = (newPassword, confirmPassword) => {
    return newPassword === confirmPassword;
  };

  const handleChangePersonalInfo = (event) => {
    const { name, value } = event.target;

    switch (name) {
      case 'nome':
        setLocalNome(value);
        setNomeError(validateNome(value) ? '' : 'Nome inválido.');
        break;
      case 'email':
        setLocalEmail(value);
        setEmailError(validateEmail(value) ? '' : 'E-mail inválido.');
        break;
      default:
        break;
    }
  };

  const handleSubmitPersonalInfo = async (event) => {
    event.preventDefault();
    setLoadingUser(true);
    if (validateNome(localNome) && validateEmail(localEmail)) {
      try {
        await updateUserContext(localNome, localEmail);
        setOpen(true);
        setErrorUpdate(false);
      } catch (error) {
        setOpen(true);
        setErrorUpdate(true);
      } finally {
        setLoadingUser(false);
      }
    } else {
      setNomeError(!validateNome(localNome) ? 'Nome inválido.' : '');
      setEmailError(!validateEmail(localEmail) ? 'E-mail inválido.' : '');
      setLoadingUser(false);
    }
  };

  const handleSubmitSecurity = async (event) => {
    event.preventDefault();
    setPasswordError('');
    setLoadingPassword(true);

    if (!isFormValid) {
      setLoadingPassword(false);
      return;
    }

    try {
      await updatePassword(currentPassword, newPassword, confirmPassword);
      setOpen(true);
      setErrorUpdate(false);

      setCurrentPassword('');
      setNewPassword('');
      setConfirmPassword('');
    } catch (error) {
      setOpen(true);
      setErrorUpdate(true);

      if (error.response && error.response.data && error.response.data.non_field_errors) {
        setErrorMessage(error.response.data.non_field_errors[0]);
      } else {
        setErrorMessage('Erro ao atualizar, tente novamente.');
      }
    } finally {
      setLoadingPassword(false);
    }
  };

  const handleChangePassword = (event) => {
    const { name, value } = event.target;

    switch (name) {
      case 'currentPassword':
        setCurrentPassword(value);
        setCurrentPasswordError(value ? '' : 'Campo de senha atual vazio.');
        setCurrentPasswordError(value.length >= 6 ? '' : 'A senha deve ter no mínimo 6 caracteres.');
        break;
      case 'newPassword':
        setNewPassword(value);
        setPasswordError(value === confirmPassword ? '' : 'Senhas não coincidem.');
        setPasswordError(value.length >= 6 ? '' : 'A senha deve ter no mínimo 6 caracteres.');
        break;
      case 'confirmPassword':
        setConfirmPassword(value);
        setPasswordError(newPassword === value ? '' : 'Senhas não coincidem.');
        break;
      default:
        break;
    }
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  return (
    <div className="myaccount">
      <div className="container">
        <div className="main_panel">
          <h2 className='text-3xl font-bold mb-4'>Minha conta</h2>
          <hr className='border-neutral-700'/>
          <h6>Informações pessoais</h6>
          <Box component="form" onSubmit={handleSubmitPersonalInfo} noValidate sx={{ mt: 1 }}>
            <TextField
              variant="filled"
              margin="normal"
              required
              fullWidth
              id="nome"
              label="Nome"
              name="nome"
              autoComplete="name"
              autoFocus
              value={localNome}
              onChange={handleChangePersonalInfo}
              error={!!nomeError}
              helperText={nomeError}
              className="custom_textfield"
              disabled={nomeLoading}
              InputLabelProps={{ shrink: true }}
              InputProps={{
                endAdornment: (
                  nomeLoading ? <CircularProgress color="inherit" size={20} /> : null
                ),
              }}
            />
            <TextField
              variant="filled"
              margin="normal"
              required
              fullWidth
              id="email"
              label="E-mail"
              name="email"
              autoComplete="email"
              value={localEmail}
              onChange={handleChangePersonalInfo}
              error={!!emailError}
              helperText={emailError}
              className="custom_textfield"
              disabled={emailLoading}
              InputLabelProps={{ shrink: true }}
              InputProps={{
                endAdornment: (
                  emailLoading ? <CircularProgress color="inherit" size={20} /> : null
                ),
              }}
            />
            <Button
              type="submit"
              className="btn_edit"
              disabled={loadingUser}
              style={{
                color: loadingUser ? "transparent" : "",
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              {loadingUser ? <CircularProgress size={24} style={{ color: 'black' }} /> : 'Editar'}
            </Button>
          </Box>
          <br /><br />
          <h6>Segurança</h6>
          <Box component="form" onSubmit={handleSubmitSecurity} noValidate sx={{ mt: 1 }}>
            <TextField
              variant="filled"
              margin="normal"
              required
              fullWidth
              name="currentPassword"
              label="Senha Atual"
              type={showPassword ? 'text' : 'password'}
              id="currentPassword"
              autoComplete="current-password"
              value={currentPassword}
              onChange={handleChangePassword}
              error={!!currentPasswordError}
              helperText={currentPasswordError}
              className="custom_textfield"
              InputLabelProps={{ shrink: true }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      className="icon_visibility"
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              variant="filled"
              margin="normal"
              required
              fullWidth
              name="newPassword"
              label="Nova Senha"
              type={showPassword ? 'text' : 'password'}
              id="newPassword"
              autoComplete="new-password"
              value={newPassword}
              onChange={handleChangePassword}
              error={!!passwordError}
              helperText={passwordError}
              className="custom_textfield"
              InputLabelProps={{ shrink: true }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      className="icon_visibility"
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              variant="filled"
              margin="normal"
              required
              fullWidth
              name="confirmPassword"
              label="Confirmar Senha"
              type={showPassword ? 'text' : 'password'}
              id="confirmPassword"
              autoComplete="new-password"
              value={confirmPassword}
              onChange={handleChangePassword}
              error={!!passwordError}
              helperText={passwordError}
              className="custom_textfield"
              InputLabelProps={{ shrink: true }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      className="icon_visibility"
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <div className="btns_form_myaccount">
              <Button
                type="submit"
                className="btn_edit"
                disabled={loadingPassword}
                style={{
                  color: loadingPassword ? "transparent" : "",
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}
              >
                {loadingPassword ? <CircularProgress size={24} style={{ color: 'black' }} /> : 'Atualizar'}
              </Button>
            </div>

            <Snackbar
              className={errorUpdate ? 'snackbar-custom error' : 'snackbar-custom success'}
              open={open}
              autoHideDuration={7000}
              onClose={handleClose}
              message={errorUpdate ? errorMessage : 'Informações atualizadas com sucesso!'}
              action={
                <div color="secondary" size="small" onClick={handleClose}>
                  <CloseIcon />
                </div>
              }
            />
          </Box>
        </div>
      </div>
    </div>
  );
};

export default MyAccount;
