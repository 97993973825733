import axios from 'axios';
import { QueryClient } from '@tanstack/react-query';
import setAxiosInterceptors from './setAxiosInterceptors';

//const localhost = 'http://localhost:3010/';
const production = 'https://dashboard-module-api-production.up.railway.app/';

const queryClient = new QueryClient();
const axiosInstance = axios.create({ baseURL: production });
setAxiosInterceptors(queryClient, axiosInstance, setAxiosToken); //return a message

function setAxiosToken(accessToken) {
  axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
}

export { axiosInstance, queryClient };
