import React, { useState } from 'react';
import Menu from '../../../components/Dashboard/Menu/Menu';
import Panel from '../../../components/Dashboard/Panel/Panel';
import Header from '../../../components/Dashboard/Header/Header';
import './DashboardPage.scss';

const DashboardPage = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  const handleMenuToggle = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <div className="relative flex flex-col h-screen">
      <Header className="fixed top-0 w-full z-10" onMenuToggle={handleMenuToggle} />
      {menuOpen && <div className="fixed inset-0 bg-black opacity-50 z-10 md:hidden" onClick={handleMenuToggle}></div>}
      <div className="mt-2 flex flex-1 pt-16 p-6">
        <div className={`fixed z-20 h-full md:static md:block ${menuOpen ? 'block' : 'hidden'}`}>
          <Menu />
        </div>
        <div className="relative flex-1 md:ml-64 overflow-auto">
          <Panel />
        </div>
      </div>
    </div>
  );
};

export default DashboardPage;
