import client from './client';
import { createBrowserHistory } from 'history';

const browserHistory = createBrowserHistory();

const requireAuth = async () => {
  const accessToken = localStorage.getItem('accessToken');
  const refreshToken = localStorage.getItem('refreshToken');

  if (accessToken) {
    try {
      const userResponse = await client.get('me', {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      });
      return userResponse.data;
    } catch (error) {
      console.error('AccessToken validation failed:', error);
      if (error.response.status !== 401) {
        throw error;
      }
    }
  }
  
  if (!refreshToken) {
    console.error('No refresh token available');
    browserHistory.push('/login');
    throw new Error('Authentication required');
  }

  try {
    const response = await client.post('authentication/refresh-token/', { refresh: refreshToken });
    localStorage.setItem('accessToken', response.data.access);
    return response.data;
  } catch (error) {
    console.error('Failed to refresh token:', error);
    browserHistory.push('/login');
    throw error;
  }
};

const authToken = { requireAuth };
export default authToken;
