import React, { useState, useEffect } from 'react';
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  TextField,
  Grid,
  Button,
  Snackbar,
  Dialog,
  DialogActions,
  CircularProgress,
  IconButton,
} from '@mui/material';
import ImportExportIcon from '@mui/icons-material/ImportExport';
import SearchIcon from '@mui/icons-material/Search';
import { fetchClients, searchClients, updateClientData, uploadClients } from '../../../api/Clients';
import ClientUser from '../../../components/Dashboard/ClientDrawer/ClientUser';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import './Clients.scss';

export const Clients = () => {
  const [clients, setClients] = useState([]);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [cpf, setCpf] = useState('');
  const [phone, setPhone] = useState('');
  const [selectedClient, setSelectedClient] = useState(null);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    handleFetchClients();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!name && !email && !cpf && !phone) {
      handleFetchClients();
    }
    // eslint-disable-next-line
  }, [name, email, cpf, phone]);

  const handleFetchClients = async () => {
    setLoading(true);
    try {
      const data = await fetchClients({ name, email, cpf, phone });
      setClients(data);
    } catch (error) {
      setErrorMessage('Erro ao buscar clientes.');
      setOpenSnackbar(true);
    } finally {
      setLoading(false);
    }
  };

  const handleSearch = async () => {
    setLoading(true);
    try {
      const filters = { name, email, cpf, phone };
      const data = await searchClients(filters);
      setClients(data);
    } catch (error) {
      setErrorMessage('Nenhum mentorado encontrado.');
      setOpenSnackbar(true);
    } finally {
      setLoading(false);
    }
  };

  const handleTableRowClick = (client) => {
    setSelectedClient({ clientData: client });
    setDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
    setSelectedClient(null);
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      uploadFile(file);
    }
  };

  const uploadFile = async (file) => {
    setUploading(true);
    try {
      const formData = new FormData();
      formData.append('file', file);

      await uploadClients(formData);
      handleFetchClients(); // Refresh the clients list after upload
      setDialogOpen(false); // Close dialog after successful upload
    } catch (error) {
      setErrorMessage('Erro ao fazer upload dos dados.');
      setOpenSnackbar(true);
    } finally {
      setUploading(false);
    }
  };

  const handleOpenDialog = () => {
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  const handleUpdateClient = async (updatedClient) => {
    try {
      await updateClientData(updatedClient);
      handleFetchClients(); // Atualiza a lista de clientes após a atualização
      setDrawerOpen(false);
    } catch (error) {
      setErrorMessage('Erro ao atualizar cliente.');
      setOpenSnackbar(true);
    }
  };

  return (
    <div className='clients'>
      <Paper className='paper_top'>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={3}>
            <TextField
              variant="filled"
              label="Nome"
              value={name}
              onChange={(e) => setName(e.target.value)}
              fullWidth
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              variant="filled"
              label="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              fullWidth
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              variant="filled"
              label="CPF"
              value={cpf}
              onChange={(e) => setCpf(e.target.value)}
              fullWidth
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              variant="filled"
              label="Telefone"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} className='flex gap-4'>
            <Button variant="contained" onClick={handleSearch}><SearchIcon />Filtrar</Button>
            <Button variant="contained" onClick={handleOpenDialog}><ImportExportIcon />Importar Mentorados</Button>
          </Grid>
        </Grid>
      </Paper>
      <Paper className='paper_table'>
        {loading ? (
          <div className="flex justify-center py-4">
            <CircularProgress className='circular_progress'/>
          </div>
        ) : (
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Nome</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>CPF</TableCell>
                <TableCell>Telefone</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {clients.map((client) => (
                <TableRow key={client.id} onClick={() => handleTableRowClick(client)}>
                  <TableCell>{client.name}</TableCell>
                  <TableCell>{client.email}</TableCell>
                  <TableCell>{client.cpf}</TableCell>
                  <TableCell>{client.phone}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}
      </Paper>
      <ClientUser
        open={drawerOpen}
        onClose={handleDrawerClose}
        client={selectedClient}
        updateClient={handleUpdateClient}
      />
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        message={errorMessage}
        className='snackbar-custom blank'
      />
      <Dialog open={dialogOpen} onClose={handleCloseDialog}>
        <div className='w-[450px] text-white p-6'>
          <h1 className='text-xl font-500 mb-4'>Importar Mentorados</h1>

          <p>Faça o upload de uma planilha com as seguintes colunas:</p>
          <b className='text-contrast'>Nome, Email, Identificador e Telefone</b>

          <div className='mt-4 mb-4 p-4 m-auto flex justify-center'>
            <input
              accept=".xlsx, .xls"
              style={{ display: 'none' }}
              id="contained-button-file"
              type="file"
              onChange={handleFileUpload}
            />
            <label htmlFor="contained-button-file" className='border border-gray-700 rounded-md'>
              <IconButton component="span">
                <UploadFileIcon fontSize="large" className='text-contrast' />
              </IconButton>
            </label>
            {uploading && <CircularProgress className='ml-2' />}
          </div>

          <DialogActions>
            <Button onClick={handleCloseDialog} color="primary">Cancelar</Button>
          </DialogActions>
        </div>
      </Dialog>
    </div>
  );
};

export default Clients;
