import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './Login.scss';
import Logo from '../../assets/images/logo.avif';
import {
  TextField,
  Button,
  Container,
  Box,
  InputAdornment,
  IconButton,
  CircularProgress
} from '@mui/material';
import Visibility from '@mui/icons-material/VisibilityOutlined';
import VisibilityOff from '@mui/icons-material/VisibilityOffOutlined';
import authService from '../../api/authService';
import { useUser } from '../../context/UserContext';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [emailError, setEmailError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [loading, setLoading] = useState(false);
  const { updateUserContext, handleUserData } = useUser();
  const navigate = useNavigate();

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const validateForm = () => {
    let isValid = true;
    setEmailError('');
    setPasswordError('');

    if (!email) {
      setEmailError('E-mail é obrigatório.');
      isValid = false;
    } else if (!/^\S+@\S+\.\S+$/.test(email)) {
      setEmailError('E-mail inválido.');
      isValid = false;
    }

    if (!password) {
      setPasswordError('Senha é obrigatória.');
      isValid = false;
    }

    return isValid;
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    if (e.target.value) {
      setEmailError('');
    }
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
    if (e.target.value) {
      setPasswordError('');
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (validateForm()) {
      setLoading(true);
      try {
        await authService.login(email, password, updateUserContext);
        await handleUserData(); // Carregar dados do usuário no contexto após o login
        navigate('/dashboard');
      } catch (error) {
        if (error.response) {
          if (error.response.status === 401) {
            setPasswordError('Senha inválida.');
          } else if (error.response.status === 404) {
            setEmailError('E-mail não encontrado.');
          } else {
            const errorMessage = error.response.data.non_field_errors;
            if (errorMessage) {
              setEmailError(errorMessage);
              setPasswordError(errorMessage);
            } else {
              setEmailError('Erro desconhecido.');
            }
          }
        } else if (error.request) {
          setPasswordError('Nenhuma resposta do servidor, tente novamente mais tarde.');
        } else {
          setPasswordError('Erro ao configurar o pedido de login.');
        }
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <div className="bg-background rounded p-6">
        <img src={Logo} alt="Logo" className="h-16 m-auto" />
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          <TextField
            variant="filled"
            margin="normal"
            required
            fullWidth
            id="email"
            label="E-mail"
            name="email"
            autoComplete="email"
            autoFocus
            value={email}
            onChange={handleEmailChange}
            error={!!emailError}
            helperText={emailError}
            className="custom_textfield"
          />
          <TextField
            variant="filled"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Senha"
            type={showPassword ? 'text' : 'password'}
            id="password"
            autoComplete="current-password"
            value={password}
            onChange={handlePasswordChange}
            error={!!passwordError}
            helperText={passwordError}
            className="custom_textfield"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    className="icon_visibility"
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <Link to="/recuperar-senha" className="forgot_password">
            Esqueci minha senha
          </Link>
          <Button
            type="submit"
            className="button_login"
            disabled={loading}
            style={{
              color: loading ? "transparent" : "",
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            {loading ? <CircularProgress size={24} style={{ color: '#161A1D' }} /> : 'Entrar'}
          </Button>
        </Box>
      </div>
    </Container>
  );
};

export default Login;
