import { axiosInstance } from './axiosConfig';

// Responsável por buscar os mentorados registrados
export const fetchClients = async (filters) => {
  try {
    const response = await axiosInstance.get('clients', { params: filters });
    return response.data;
  } catch (error) {
    throw error;
  }
};

// Responsável por buscar mentorados a partir dos dados enviados
export const searchClients = async (filters) => {
  try {
    const response = await axiosInstance.post('clients/search-alls', filters);
    return response.data;
  } catch (error) {
    throw error;
  }
};

// Responsável pela atualização dos dados do cliente
export const updateClientData = async (client) => {
  try {
    const response = await axiosInstance.put(`clients/${client.id}`, {
      name: client.name,
      cpf: client.cpf,
      phone: client.phone,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

// Responsável pela inserção dos Mentorados
export const uploadClients = async (formData) => {
  try {
    const response = await axiosInstance.post('clients/upload', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};