import client from '../api/client';

const login = async (email, password) => {
  try {
    const response = await client.post('login', { email, password });
    localStorage.setItem('accessToken', response.data.accessToken);
    localStorage.setItem('refreshToken', response.data.refreshToken);
    
    return response.data;

  } catch (error) {
    throw error;
  }
};

const authService = { login }; 
export default authService;
