import { axiosInstance } from './axiosConfig';

export const fetchUserData = async () => {
  try {
    const accessToken = localStorage.getItem('accessToken');
    if (!accessToken) throw new Error("Token de acesso não encontrado.");

    const response = await axiosInstance.get('me', {
        headers: {
            Authorization: `Bearer ${accessToken}`
        }
    });
    return response.data;
  } catch (error) {
    console.error('Erro ao buscar dados do usuário:', error);
    throw error;
  }
};


