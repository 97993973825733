import React from 'react';
import { Routes, Route } from 'react-router-dom';

//Admin
import LoginPage from '../pages/Admin/LoginPage/LoginPage';
import PasswordRecoveryPage from '../pages/Admin/PasswordRecoveryPage/PasswordRecoveryPage';
import PasswordConfirmPage from '../pages/Admin/PasswordConfirmPage/PasswordConfirmPage';
import DashboardPage from '../pages/Admin/DashboardPage/DashboardPage';
import FinancePage from '../pages/Admin/FinancePage/FinancePage';
import ClientsPage from '../pages/Admin/ClientsPage/ClientsPage';
import ProductsPage from '../pages/Admin/ProductsPage/ProductsPage';
import MyAccountPage from '../pages/Admin/MyAccountPage/MyAccountPage';
import PrivateRoute from '../routes/PrivateRoute';

//Client
//import LoginPageClient from '../pages/Client/LoginPage/LoginPageClient';
import PasswordConfirm from '../pages/Admin/PasswordConfirmPage/PasswordConfirmPage';
import LogoutPage from '../pages/Admin/LogoutPage/LogoutPage';

const AppRoutes = () => {
  return (
    <Routes>
      {/* Released routes - Public */}
      <Route path="/admin" element={<LoginPage />} />
      <Route path="/recuperar-senha" element={<PasswordRecoveryPage />} />
      <Route path="/redefinir-senha" element={<PasswordConfirm />} />
      <Route path="/confirmar-senha" element={<PasswordConfirmPage />} />
      <Route path="/sair" element={<LogoutPage />} />

      {/* Routes blocked waiting for correct login - Private */}
      <Route path="/dashboard" element={<PrivateRoute><DashboardPage /></PrivateRoute>} />
      <Route path="/financas" element={<PrivateRoute><FinancePage /></PrivateRoute>} />
      <Route path="/mentorados" element={<PrivateRoute><ClientsPage /></PrivateRoute>} />
      <Route path="/produtos" element={<PrivateRoute><ProductsPage /></PrivateRoute>} />
      <Route path="/configuracoes" element={<PrivateRoute><DashboardPage /></PrivateRoute>} />
      <Route path="/minha-conta" element={<PrivateRoute><MyAccountPage /></PrivateRoute>} />

       {/* Released routes - Dashboard Public */}
       <Route path="*" element={<LoginPage />} />
       <Route path="/login" element={<LoginPage />} />

    </Routes>
  );
}

export default AppRoutes;
