import { axiosInstance } from './axiosConfig';

const updateUser = async (name, email) => {
    try {
        const response = await axiosInstance.post('update-info', {
            name: name,
            email: email
        });
        return response.data;  // Returns the API response.

    } catch (error) {
        throw error;  // Rethrows the error for further handling if necessary.
    }
};

export default updateUser;
