import React from 'react';
import { Drawer } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { format } from 'date-fns';
import PagarmeImage from '../../../assets/images/pagarme.png'; // Altere o caminho conforme necessário
import AsaasImage from '../../../assets/images/asaas.png'; // Altere o caminho conforme necessário

const ClientDrawer = ({ open, onClose, client, formatToCurrency }) => {
  // Função para formatar a data
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return format(date, 'dd/MM/yyyy');
  };

  // Função para obter a classe de status
  const getStatusClass = (status) => {
    switch (status) {
      case 'RECEIVED':
        return 'status_received';
      case 'PENDING':
        return 'status_pending';
      case 'CANCELLED':
        return 'status_cancelled';
      case 'REFUNDED':
        return 'status_refunded';
      case 'OVERDUE':
            return 'status_overdue';
      case 'paid':
        return 'status_received';
      case 'pending':
        return 'status_pending';
      case 'waiting_payment':
        return 'status_pending';
      case 'canceled':
        return 'status_cancelled';
      case 'failed':
        return 'status_cancelled';
      case 'refused':
        return 'status_cancelled';
      case 'refunded':
        return 'status_refunded';
      default:
        return '';
    }
  };

  return (
    <Drawer anchor="right" open={open} onClose={onClose} className='client_base'>
      <div>
        {client && client.clientData && (
          <div className='content_client_base'>
            <div className="flex justify-between">
              <h2 className='text-xl mb-4'>Dados do Cliente</h2>
              <div onClick={onClose} className='exit_client mb-4 cursor-pointer text-right'><CloseIcon /></div>
            </div>
            <div className='client_user'>
              <div className='info_client_name'>
                <AccountCircleIcon className='icon_user' />
                <h4>{client.clientData.name}</h4>
              </div>
              <div className='info_client'>
                <b>Email</b>
                {client.clientData.email}
              </div>
              <div className='info_client'>
                <b>CPF</b>
                {client.clientData.cpf}
              </div>
              <div className='info_client'>
                <b>Telefone</b>
                {client.clientData.phone}
              </div>
              <div className='info_client total_paid'>
                <b>Total Pago</b>
                <p>{formatToCurrency(client.totalPaid)}</p>
              </div>
            </div>
            <div className='client_payments'>
              <h3 className='text-xl mt-4 mb-4'>Pagamentos</h3>
              <div className='list_payments_client'>
                {client.clientPayments && client.clientPayments.map((payment) => (
                  <div key={payment.id_payment} className={`row_list ${getStatusClass(payment.status)}`}>
                    <div className='flex items-center justify-between'>
                      <p>{payment.description}</p>
                      {payment.platform === 'Pagarme' && <img src={PagarmeImage} alt="Pagarme" className='w-20 rounded' />}
                      {payment.platform === 'Asaas' && <img src={AsaasImage} alt="Asaas" className='w-20 rounded' />}
                    </div>
                    <div className='pt-4 flex items-center justify-between'>
                      <p><b>{formatToCurrency(payment.value)}</b></p>
                      <p>{formatDate(payment.due_date)}</p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}
      </div>
    </Drawer>
  );
};

export default ClientDrawer;
