import React, { useState, useEffect } from 'react';
import { Drawer, TextField, Button } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';

const ClientUser = ({ open, onClose, client, updateClient }) => {
  const [editableClient, setEditableClient] = useState({});

  useEffect(() => {
    if (client && client.clientData) {
      setEditableClient(client.clientData);
    }
  }, [client]);

  const handleChange = (field, value) => {
    setEditableClient({ ...editableClient, [field]: value });
  };

  const handleUpdate = () => {
    updateClient(editableClient);
  };

  return (
    <Drawer anchor="right" open={open} onClose={onClose} className='client_base'>
      <div>
        {editableClient && (
          <div className='content_client_base'>
            <div className="flex justify-between">
              <h2 className='text-xl mb-4'>Dados do Cliente</h2>
              <div onClick={onClose} className='exit_client mb-4 cursor-pointer text-right'><CloseIcon /></div>
            </div>
            <div className='client_user'>
              <div className='info_client_name'>
                <AccountCircleIcon className='icon_user' />
                <h4>{editableClient.name}</h4>
              </div>
              <div className='info_client'>
                <TextField
                  label="Nome"
                  value={editableClient.name || ''}
                  onChange={(e) => handleChange('name', e.target.value)}
                  fullWidth
                  margin="normal"
                  variant="filled"
                />
              </div>
              <div className='info_client'>
                <TextField
                  label="Email"
                  value={editableClient.email || ''}
                  fullWidth
                  margin="normal"
                  InputProps={{ readOnly: true }}
                  variant="filled"
                />
              </div>
              <div className='info_client'>
                <TextField
                  label="CPF"
                  value={editableClient.cpf || ''}
                  onChange={(e) => handleChange('cpf', e.target.value)}
                  fullWidth
                  margin="normal"
                  variant="filled"
                />
              </div>
              <div className='info_client'>
                <TextField
                  label="Telefone"
                  value={editableClient.phone || ''}
                  onChange={(e) => handleChange('phone', e.target.value)}
                  fullWidth
                  margin="normal"
                  variant="filled"
                />
              </div>
              <Button
                variant="contained"
                color="primary"
                onClick={handleUpdate}
                fullWidth
              >
                Atualizar Dados
              </Button>
            </div>
          </div>
        )}
      </div>
    </Drawer>
  );
};

export default ClientUser;
