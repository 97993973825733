import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './PasswordConfirm.scss';
import {
  TextField,
  Button,
  Container,
  Box
} from '@mui/material';
import LockIcon from '@mui/icons-material/Lock';
import CheckIcon from '@mui/icons-material/Check';

const PasswordConfirm = () => {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [sentSuccess, setSentSuccess] = useState(null);

  const validateForm = () => {
    if (password !== confirmPassword) {
      setPasswordError('As senhas não coincidem.');
      return false;
    } else {
      setPasswordError('');
      return true;
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (validateForm()) {
      console.log("Senha:", password);
      // Lógica de envio aqui
      setSentSuccess(true);
    } else {
      setSentSuccess(false);
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <div className="confirm_form">

        {sentSuccess !== null ? (
          <>
            <div className="m-auto bg-contrast rounded-full p-4 text-textContrast">
              <LockIcon/>
            </div>

            <div className="text-xl">
              Crie sua nova senha
            </div>

            <div className="information">
              <p>
                Informe a nova senha que utilizará para acessar a plataforma
              </p>
            </div>

            <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
            <TextField
                variant="filled"
                margin="normal"
                required
                fullWidth
                id="senha"
                label="Senha"
                type="password"
                name="senha"
                autoComplete="new-password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                error={!!passwordError}
                helperText={passwordError}
              />

              <TextField
                variant="filled"
                margin="normal"
                required
                fullWidth
                id="confirmSenha"
                label="Confirme sua Senha"
                type="password"
                name="confirmSenha"
                autoComplete="new-password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                error={!!passwordError}
                helperText={passwordError}
              />

              <Button className="button_login">
                Confirmar alteração de senha
              </Button>

            </Box>
          </>

        ) : (
          <>
           <div className="m-auto bg-contrast rounded-full p-4 text-textContrast">
              <CheckIcon/>
            </div>

            <div className="text-xl">
              Sua senha foi alterada
            </div>

            <div className="verify_email">
              Clique no botão e acesse a plataforma utilizando a sua nova senha.
            </div>

            <Link to="/login" className="button_login">Entrar com nova senha</Link>
          </>
        )}

      </div>


    </Container>
  );
};

export default PasswordConfirm;
