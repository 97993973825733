// App.js
import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import AppRoutes from './routes/AppRoutes';
import { useTheme } from './context/ThemeContext';
import './styles/_theme.scss';

function App() {
  const { theme } = useTheme();
  
  return (
    <div className={`App ${theme === 'light' ? 'body-light' : 'body-dark'}`}>
      <Router>
          <main>
            <AppRoutes />
          </main>
      </Router>
    </div>
  );
}

export default App;
