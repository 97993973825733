import React from 'react';
import PasswordConfirm from '../../../components/PasswordConfirm/PasswordConfirm';
import './PasswordConfirmPage.scss';

const PasswordConfirmPage = () => {
  const handleLogin = (username, password) => {
    console.log("Login attempt with:", username, password);
    // Lógica de envio
  };

  return (
    <div className="PasswordConfirmPage">
      <PasswordConfirm onLogin={handleLogin} />
    </div>
  );
};

export default PasswordConfirmPage;
